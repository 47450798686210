import { NgModule, PLATFORM_ID, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FbRtdbProvider } from './services/fb-rtdb/fb-rtdb';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { tfimiscFBConfig } from './app.constants';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabasetfimiscService, AngularFireDatabasetfimiscFactory, AngularFireDatabasetficatalogService, AngularFireDatabasetficatalogFactory } from './services/rest/angularfiredatabase-extension-service';
import { TfimiscProvider } from './services/tfimisc/tfimisc';
import { AuthenticationService } from './services/rest/authentication-service';
import { CatalogService } from './services/rest/catalog-service';
import { VehicleOptionService } from './services/vehicle-option/vehicle-option';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { EncoreCompany1Provider } from './services/encore-company1/encore-company1';
import { EncoreCompany1Service } from './services/rest/encore-company-1-service';
import { DatePipe, ViewportScroller } from '@angular/common';
import { TestTankService } from './services/rest/test-tank-service';
import { APSService } from './services/rest/aps-service';
import { TestDetailsComponent } from './components/test-details/test-details.component';
import { UtilityService } from './services/rest/utility-service';
import { SuggestionModel } from './services/tfidb/tfidb.service';
import { EditGLComponent } from './components/edit-gl/edit-gl.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PlatformModule } from '@angular/cdk/platform';
import { AvatarPopoverComponent } from './components/avatar-popover/avatar-popover.component';

@NgModule({
  declarations: [AppComponent, AvatarPopoverComponent],
  entryComponents: [AvatarPopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(tfimiscFBConfig, 'tfimisc'),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    PlatformModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FbRtdbProvider,
    TfimiscProvider,
    AngularFireDatabase,
    { provide: AngularFireDatabasetfimiscService, deps: [PLATFORM_ID, NgZone], useFactory: AngularFireDatabasetfimiscFactory },
    { provide: AngularFireDatabasetficatalogService, deps: [PLATFORM_ID, NgZone], useFactory: AngularFireDatabasetficatalogFactory },
    AuthenticationService,
    CatalogService,
    VehicleOptionService,
    HttpClient,
    EncoreCompany1Provider,
    EncoreCompany1Service,
    DatePipe,
    TestTankService,
    APSService,
    TestDetailsComponent,
    UtilityService,
    SuggestionModel,
    EditGLComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
