import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication-service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class CatalogService {

    static token: string;
    static _http: HttpClient;
    static authKey: string;
    static param: string;
    static loadCount: number = 0;
    static moduleFile: any;
    static selectedVehicleOptions: any;

    constructor(private http: HttpClient
    ) {
        CatalogService._http = http;
        CatalogService.loadCount++;
    }

    static getModuleFiles() {
        var url = 'catalogapi/tfiaspnetcore/api/modulefiles';
        var response = this._http.get(url, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static getVehicleOptions() {
        var url = 'catalogapi/tfiaspnetcore/api/vehicleoptions';
        var response = this._http.get(url, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static setNodeNames(selectedOptions: Array<string>) {
        var url = 'catalogapi/tfiaspnetcore/api/vehicles/setnodenames';
        var response = this._http.post(url, selectedOptions, { headers: AuthenticationService.headers });
        return response;
    }

    static addNodeModuleFileAssociations(associations: NodeModuleFileAssociationsModel) {
        var url = 'catalogapi/tfiaspnetcore/api/nodemodulefiles/addassociations';
        var response = this._http.post(url, associations, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static removeNodeModuleFileAssociations(associations: NodeModuleFileAssociationsModel) {
        var url = 'catalogapi/tfiaspnetcore/api/nodemodulefiles/removeassociations';
        var response = this._http.post(url, associations, { headers: AuthenticationService.headers });
        return response;
    }

    static addVehicleParts(stockCodes: Array<string>, nodes: Array<number>) {
        var url = 'catalogapi/tfiaspnetcore/api/vehicles/addvehicleparts';
        var response = this._http.post(url, { stockCodes, nodes }, { headers: AuthenticationService.headers });
        return response;
    }

    static removeVehicleParts(stockCodes: Array<string>, nodes: Array<number>) {
        var url = 'catalogapi/tfiaspnetcore/api/vehicles/removevehicleparts';
        var response = this._http.post(url, { stockCodes, nodes }, { headers: AuthenticationService.headers });
        return response;
    }

    static staticGetPartNodes(stockCodes: Array<string>) {
        var url = 'catalogapi/tfiaspnetcore/api/vehicles/getpartnodes';
        var response = this._http.post(url, stockCodes, { headers: AuthenticationService.headers });
        return response;
    }
}

export class NodeModuleFileAssociationsModel {
    moduleFileId: number;
    leafNodeIds: Array<number>;
}