import { Injectable, ChangeDetectorRef } from '@angular/core';
import { CatalogService, NodeModuleFileAssociationsModel } from '../../services/rest/catalog-service';
import { HttpClient } from '@angular/common/http';

/*
  Generated class for the VehicleOptionProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class VehicleOptionService {

  constructor(private catalogService: CatalogService,
    private http: HttpClient
  ) {
  }

  getOptions(callback: (data) => void) {
    return CatalogService.getVehicleOptions().subscribe(res => {
      callback(res.map((item) => {
        let options = [];

        item.names.forEach((nameItem) => {
          options.push({
            name: nameItem,
            checked: false
          })
        });

        item.options = options;

        return item;
      }));
    },
      err => {
        console.error(err);
      }
    );
  }


  setNodeNames(selectedOptions: Array<string>) {
    return CatalogService.setNodeNames(selectedOptions);
  }

  addNodeModuleFileAssociations(associations: NodeModuleFileAssociationsModel, callback: (data) => void) {
    CatalogService.addNodeModuleFileAssociations(associations).subscribe(res => {
      callback(res);
    },
      err => {
        console.error(err);
      }
    );
  }

  removeNodeModuleFileAssociations(associations: NodeModuleFileAssociationsModel, callback: (data) => void) {
    CatalogService.removeNodeModuleFileAssociations(associations).subscribe(res => {
      callback(res);
    },
      err => {
        console.error(err);
      }
    );
  }

  getVehicleOptions(vehicles: Array<any>, options: Array<string>, eventSource: any, changeDetector: ChangeDetectorRef, data: (any) => void,
    complete: (any) => void, error: (any) => void) {
    this.setNodeNames(options).subscribe((result) => {
      const es = new eventSource('catalogapi/tfiaspnetcore/api/vehicles/getbynodenames');
      es.onmessage = event => {
        if (event.lastEventId === '-1') {
          es.close();
          complete(event);
        }
        else {
          data(event.data);
        }
        changeDetector.detectChanges();
      }
      es.onerror = event => {
        error(event.data);
      }
    },
      (error) => {
        error(error);
      });
  }
}

export class VehicleOptionModel {
  id: number;
  checked: boolean;
  name: string;
}
