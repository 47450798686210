
/** firebase config */
export const tficatalogFBConfig = {
    apiKey: "AIzaSyCtosozyBJqA9KEudjT4Gynz2Hln0jsdIM",
    authDomain: "tficatalog.firebaseapp.com",
    databaseURL: "https://tficatalog.firebaseio.com/",
    projectId: "tficatalog",
    storageBucket: "gs://tficatalog.appspot.com",
    messagingSenderId: "192877999462"
};

export const tfimiscFBConfig = {
    apiKey: "AIzaSyCX8C0Knkd065xiIPYlXruAoYRLoOhKmWA",
    authDomain: "tfimisc-ee19e.firebaseapp.com",
    databaseURL: "https://tfimisc-ee19e.firebaseio.com/",
    projectId: "tfimisc-ee19e",
    storageBucket: "gs://tfimisc-ee19e.appspot.com",
    messagingSenderId: "145848600392"
};

export const passwdFBConfig = {
    apiKey: "AIzaSyAOhHDjy7oT3s_Au6vLW2bySdeiPgUFzbI",
    authDomain: "passwd-ccd73.firebaseapp.com",
    databaseURL: "https://passwd-ccd73.firebaseio.com",
    projectId: "passwd-ccd73",
    storageBucket: "passwd-ccd73.appspot.com",
    messagingSenderId: "20760741890"
};

/** google webClientId */
export const googleWebClientId = 'GOOGLE WEB CLIENT ID';

/** firebase tables */
export const tableNames = {
    User               : 'users',
    Contacts           : 'contacts',
    ChatChannel        : 'chat_channels',
    ChatMember         : 'chat_members',
    ChatMessage        : 'chat_messages',
    Vehicles           : 'vehicles'
};

export const tfiASPNETCoreConfig = {
    AuthKey: '9KCNBh+Lx0ClDgRzsKTWag=='
}
