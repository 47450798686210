import { Component, OnInit, Input, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { PopoverController, NavController } from '@ionic/angular';
import * as firebaseui from 'firebaseui';

@Component({
  selector: 'app-avatar-popover',
  templateUrl: './avatar-popover.component.html',
  styleUrls: ['./avatar-popover.component.scss'],
})
export class AvatarPopoverComponent implements OnInit {
  @Input() redirectPage: string = '';
  email: string = '';
  signedIn: boolean = false;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private popoverController: PopoverController,
    private navController: NavController,
    private ngZone: NgZone
  ) {
    angularFireAuth.authState.subscribe(result => {
      this.email = result ? result.email : 'User signed out...';
      if (result && result.uid) {
        this.signedIn = true;
      }
      else {
        this.signedIn = false;
      }
    });
  }

  ngOnInit() { }

  signOutClick() {
    // let ui = firebaseui.auth.AuthUI.getInstance();
    // ui.reset();
    // console.log('firebaseui: ' + ui);
    // if (!ui) ui = new firebaseui.auth.AuthUI(this.afAuth.auth);
    // ui = new firebaseui.auth.AuthUI(this.afAuth.auth);
    // ui.reset();

    this.router.navigate(['/login', this.redirectPage]);

    // this.navController.setDirection('root');
    // this.navController.navigateRoot('').finally(() => {
    //   this.navController.navigateRoot('');
    // });


    // this.navController.navigateRoot('');
    // this.navController.pop();
    // this.router.navigate(['/login', this.redirectPage]);

    // this.angularFireAuth.auth.signOut().finally(() => {
    //   this.popoverController.dismiss();
    //   this.router.navigate(['/login', '']);
    // });
  }

  async signInClick() {
    this.router.navigate(['/login', this.redirectPage]);
    await this.popoverController.dismiss();
  }
}
