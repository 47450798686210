import { Injectable } from '@angular/core';
import { EncoreCompany1Service } from '../../services/rest/encore-company-1-service';

/*
  Generated class for the EncoreCompany1Provider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class EncoreCompany1Provider {

  constructor(ec1s: EncoreCompany1Service) {
  }

  getTankQuantitiesSold(tqsParameters: any, callback: (data) => void) {
    return EncoreCompany1Service.getTankQuantitiesSold(tqsParameters).subscribe(res => {
      callback(res.map((item) => {
        return item;
      }));
    },
      err => {
        console.error(err);
      }
    );
  }

  getStockCodes(callback: (data) => void) {
    return EncoreCompany1Service.getStockCodes().subscribe(res => {
      callback(res.map((item) => {
        return item;
      }));
    },
      err => {
        console.error(err);
      }
    );
  }
}
