import { Injectable, NgZone } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { tfimiscFBConfig, tficatalogFBConfig } from '../../app.constants';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class AngularFireDatabasetfimiscService extends AngularFireDatabase {}

export function AngularFireDatabasetfimiscFactory(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(tfimiscFBConfig, 'tfimisc', undefined, platformId, zone);
}

export class AngularFireDatabasetficatalogService extends AngularFireDatabase {}

export function AngularFireDatabasetficatalogFactory(platformId: Object, zone: NgZone) {
  return new AngularFireDatabase(tficatalogFBConfig, 'tficatalog', undefined, platformId, zone);
}
