import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication-service';
import { map } from 'rxjs/operators';

@Injectable()

export class APSService {

    static token: string;
    static _http: HttpClient;
    static authKey: string;
    static param: string;
    static loadCount: number = 0;

    public static persons: any;

    constructor(private http: HttpClient
    ) {
        APSService._http = http;
        APSService.loadCount++;
    }

    static getPersons() {
        var url = 'catalogapi/tfiaspnetcore/api/aps/persons';
        var response = this._http.get(url, { headers: AuthenticationService.headers }).pipe(
            map(res => res)
        );
        response.subscribe(
            data => {
                this.persons = data;
            },
            err => {
                console.error(err);
            }
        );
    }

    // static getVehicleOptions() {
    //     var url = 'catalogapi/tfiaspnetcore/api/vehicleoptions';
    //     var response = this._http.get(url, { headers: AuthenticationService.headers }).map(res => res.json());
    //     return response;
    // }

    // static getVehicles(selectedOptions: Array<string>) {
    //     var url = 'catalogapi/tfiaspnetcore/api/vehicles/getbynodenames';
    //     var response = this._http.post(url, selectedOptions, { headers: AuthenticationService.headers }).map(res => res.json());
    //     return response;
    // }

    // static addNodeModuleFileAssociations(associations: NodeModuleFileAssociationsModel) {
    //     var url = 'catalogapi/tfiaspnetcore/api/nodemodulefiles/addassociations';
    //     var response = this._http.post(url, associations, { headers: AuthenticationService.headers }).map(res => res.json());
    //     return response;
    // }
}

export class NodeModuleFileAssociationsModel {
    moduleFileId: number;
    leafNodeIds: Array<number>;
}