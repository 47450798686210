import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../rest/authentication-service';

@Injectable({
  providedIn: 'root'
})
export class TFIDBService {

  static _http: HttpClient;

  constructor(
    private http: HttpClient
  ) {
    TFIDBService._http = http;
  }

  static getSuggestion(suggestionId: string) {
    var url = 'catalogapi/tfiaspnetcore/api/tfidb/getsuggestion';
    var response = this._http.post(url, suggestionId, { headers: AuthenticationService.headers });
    return response;
  }

  static getSuggestionStatuses() {
    var url = 'catalogapi/tfiaspnetcore/api/tfidb/getsuggestionstatuses';
    var response = this._http.post(url, {}, { headers: AuthenticationService.headers });
    return response;
  }

  static upsertSuggestion(suggestion: SuggestionModel) {
    var url = 'catalogapi/tfiaspnetcore/api/tfidb/upsertsuggestion';
    var response = this._http.post(url, { suggestion }, { headers: AuthenticationService.headers });
    return response;
  }

  static getAssetAudit(tag: string) {
    var url = 'catalogapi/tfiaspnetcore/api/tfidb/getassetaudit';
    var response = this._http.post(url, tag, { headers: AuthenticationService.headers });
    return response;
  }

  static upsertAssetAudit(assetAudit: AssetAuditModel) {
    var url = 'catalogapi/tfiaspnetcore/api/tfidb/upsertassetaudit';
    var response = this._http.post(url, { assetAudit }, { headers: AuthenticationService.headers });
    return response;
  }
}

export class SuggestionModel {
  id?: number;
  employeeId?: number;
  employeeEmail?: string;
  submittedBy?: string;
  suggestion?: string;
  notes?: string;
  dateSubmitted?: Date;
  status?: string;
  dateResolved?: Date;
  showInApp?: boolean;
  userId: number;
  dateStamp: Date
}

export class AssetAuditModel {
  id?: number;
  assetTag?: string;
  audit?: boolean;
  dateLastAudit?: Date;
  auditNotes?: string;
  dateStamp?: Date;
  employeeId?: number;
}
