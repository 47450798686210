import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication-service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class UtilityService {

    static token: string;
    private _http: HttpClient;
    static authKey: string;

    constructor(private http: HttpClient
    ) {
        this._http = http;
    }

    sendMail(sendMailParameters: any) {
        var url = 'catalogapi/tfiaspnetcore/api/utility/sendmail';
        var response = this._http.post(url, sendMailParameters, { headers: AuthenticationService.headers });
        return response;
    }

    get isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }
}
