import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './general-ui/auth/login/login.module#LoginPageModule' },
  { path: 'login/:redirectPage', loadChildren: './general-ui/auth/login/login.module#LoginPageModule' },
  { path: 'login/:redirectPage/:redirectPage2', loadChildren: './general-ui/auth/login/login.module#LoginPageModule' },
  { path: 'main-menu', loadChildren: './general-ui/main-menu/main-menu.module#MainMenuPageModule' },
  { path: 'approval-pending', loadChildren: './general-ui/auth/approval-pending/approval-pending.module#ApprovalPendingPageModule' },
  { path: 'tfi-reports-shortcuts', loadChildren: './general-ui/tfi-reports/tfi-reports-shortcuts/tfi-reports-shortcuts.module#TfiReportsShortcutsPageModule' },
  { path: 'tfi-reports-shortcuts/:username', loadChildren: './general-ui/tfi-reports/tfi-reports-shortcuts/tfi-reports-shortcuts.module#TfiReportsShortcutsPageModule' },
  { path: 'tfi-reports-shortcuts/:username/:randomString', loadChildren: './general-ui/tfi-reports/tfi-reports-shortcuts/tfi-reports-shortcuts.module#TfiReportsShortcutsPageModule' },
  { path: 'catalog-main', loadChildren: './general-ui/catalog/catalog-main/catalog-main.module#CatalogMainPageModule' },
  { path: 'module-files', loadChildren: './general-ui/catalog/module-files/module-files.module#ModuleFilesPageModule' },
  { path: 'vehicle-options', loadChildren: './general-ui/vehicles/vehicle-options/vehicle-options.module#VehicleOptionsPageModule' },
  { path: 'vehicle-list', loadChildren: './general-ui/vehicles/vehicle-list/vehicle-list.module#VehicleListPageModule' },
  { path: 'tank-type-qty-params', loadChildren: './general-ui/sales/tank-type-qtys/tank-type-qty-params/tank-type-qty-params.module#TankTypeQtyParamsPageModule' },
  { path: 'tank-type-qty/:beginDate/:endDate/:priceCode/:stockCodeFilter', loadChildren: './general-ui/sales/tank-type-qtys/tank-type-qty/tank-type-qty.module#TankTypeQtyPageModule' },
  { path: 'tank-serial-search', loadChildren: './general-ui/test-tank/tank-serial-search/tank-serial-search.module#TankSerialSearchPageModule' },
  { path: 'tank-details', loadChildren: './general-ui/test-tank/tank-details/tank-details.module#TankDetailsPageModule' },
  { path: 'job-details/:jobNumber', loadChildren: './general-ui/test-tank/job-details/job-details.module#JobDetailsPageModule' },
  { path: 'qotd', loadChildren: './general-ui/admin/qotd/qotd.module#QotdPageModule' },
  { path: 'ftl-exp', loadChildren: './general-ui/misc/ftl-exp/ftl-exp.module#FtlExpPageModule' },
  { path: 'jira-service-request-summary', loadChildren: './general-ui/tfi-reports/jira/jira-service-request-summary/jira-service-request-summary.module#JiraServiceRequestSummaryPageModule' },
  { path: 'tfi-reports-edit-shortcut', loadChildren: './general-ui/tfi-reports/tfi-reports-edit-shortcut/tfi-reports-edit-shortcut.module#TfiReportsEditShortcutPageModule' },
  { path: 'quill-edit', loadChildren: './general-ui/quill-edit/quill-edit.module#QuillEditPageModule' },
  { path: 'module-history', loadChildren: './general-ui/tfi-reports/mptk/module-history/module-history.module#ModuleHistoryPageModule' },
  { path: 'vehicle-search', loadChildren: './general-ui/catalog/vehicle-search/vehicle-search.module#VehicleSearchPageModule' },
  { path: 'add-remove-part-page', loadChildren: './general-ui/catalog/add-remove-part-page/add-remove-part-page.module#AddRemovePartPagePageModule' },
  { path: 'sfssstatus', loadChildren: './general-ui/tfi-reports/sfssstatus/sfssstatus.module#SFSSStatusPageModule' },
  { path: 'movement-values', loadChildren: './general-ui/tfi-reports/syspro/gl/movement-values/movement-values.module#MovementValuesPageModule' },
  { path: 'suggestion-box', loadChildren: './general-ui/tfi-reports/suggestion-box/submission/suggestion-box-submission.module#SuggestionBoxSubmissionPageModule' },
  { path: 'suggestion-box-review/:action/:id/:key', loadChildren: './general-ui/tfi-reports/suggestion-box/review/suggestion-box-review/suggestion-box-review.module#SuggestionBoxReviewPageModule' },
  { path: 'asset-audit', loadChildren: './general-ui/wasp/asset-audit/asset-audit.module#AssetAuditPageModule' },
  { path: 'edit-gls', loadChildren: './general-ui/tfi-reports/syspro/gl/edit-gls/edit-gls.module#EditGLsPageModule' },  { path: 'edit-tackers', loadChildren: './general-ui/test-tank/edit-tackers/edit-tackers.module#EditTackersPageModule' }






];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
