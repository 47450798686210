import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { database } from 'firebase';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/operator/map'

/*
  Generated class for the FbRtdbProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FbRtdbProvider {

  constructor(public db: AngularFireDatabase) {
  }

  setDb(newdb: AngularFireDatabase) {
    this.db = newdb;
  }

  public getList(path: string): AngularFireList<{}> {
    return this.db.list(path);
  }

  getSnapshot(path: string) {
    return this.db.database.ref(path).once('value', (snapshot) => {
      return snapshot;
    });
  }

  getReference(path: string) {
    return this.db.database.ref(path);
  }

  object(path: string): Observable<any> {
    return this.db.object(path).snapshotChanges().map(change => {
      return ({ key: change.key, ...change.payload.val() });
    });
  }

  list(path: string, query?: QueryModel): Observable<any[]> {
    return this.db.list(path, ref => query ? this.queryFn(ref, query) : ref).snapshotChanges().map(changes => {
      return changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
    });
  }

  push(path: string, data: any): any {
    return this.db.list(path).push(data);
  }

  set(path: string, key: string, data: any): Promise<void> {
    return this.db.list(path).set(key, data);
  }

  update(path: string, key: string, data: any): Promise<void> {
    return this.db.list(path).update(key, data);
  }

  updateSingle(data: any): Promise<void> {
    return this.db.database.ref().update(data);
  }

  remove(path: string, key: string): Promise<void> {
    return this.db.list(path).remove(key);
  }

  removeAll(path: string) {
    return this.db.list(path).remove();
  }

  private queryFn(ref: database.Reference, query: QueryModel): database.Query {
    let q: any;
    if (query.orderByChild)
      q = (q || ref).orderByChild(query.orderByChild);
    if (query.orderByKey)
      q = (q || ref).orderByKey();
    if (query.orderByValue)
      q = (q || ref).orderByValue();
    if (query.equalTo)
      q = (q || ref).equalTo(query.equalTo);
    if (query.limitToFirst)
      q = (q || ref).limitToFirst(query.limitToFirst);
    if (query.limitToLast)
      q = (q || ref).limitToFirst(query.limitToLast);
    if (query.startAt)
      q = (q || ref).startAt(query.startAt);
    if (query.endAt)
      q = (q || ref).endAt(query.endAt);

    return q || ref;
  }

}

export class QueryModel {
  orderByChild?: string;
  orderByKey?: boolean;
  orderByValue?: string;
  equalTo?: any;
  limitToFirst?: number;
  limitToLast?: number;
  startAt?: any;
  endAt?: any;
}
