import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication-service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class TestTankService {
    static _http: HttpClient;
    static authKey: string;
    public static selectedTankDetails: any;

    constructor(private http: HttpClient
    ) {
        TestTankService._http = http;
    }

    static getTankDetails(substring: string) {
        var url = 'catalogapi/tfiaspnetcore/api/testtank/gettankdetailsbysubstring/' + substring;
        var response = this._http.get(url, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static getEmployeeTankCountByJob(processList: any) {
        var url = 'catalogapi/tfiaspnetcore/api/testtank/GetEmployeeTankCountByJob';
        var response = this._http.post(url, processList, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }
}
