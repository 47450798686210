import { Component, OnInit, Input } from '@angular/core';
import { APSService } from 'src/app/services/rest/aps-service';

@Component({
  selector: 'app-test-details',
  templateUrl: './test-details.component.html',
  styleUrls: ['./test-details.component.scss'],
})
export class TestDetailsComponent implements OnInit {
  @Input() serialNumber: any;
  @Input() welder: any;
  @Input() tacker1: any;
  @Input() tacker1Name: any;
  @Input() tacker2: any;
  @Input() robotOperator: any;
  @Input() notes: any;
  @Input() locationId: any;
  @Input() prepEmployee: any;
  @Input() tankWash: any;
  @Input() location: any;
  @Input() testDetails: any;
  @Input() dateStamp: any;

  constructor(apsService: APSService
  ) {
  }

  ngOnInit() { }

  getEmployeeName(id) {
    var returnValue: String = '';

    var result = APSService.persons.filter(fp => fp.resId == id);
    if (result.length > 0) returnValue = result[0].fullName;

    return returnValue;
  }
}
