import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable()

export class AuthenticationService {

    static token: string;
    static _http: HttpClient;
    static authKey: string;
    static param: string;
    public static headers: HttpHeaders;
    static msToken: string = '';

    constructor(
        private http: HttpClient
    ) {
        AuthenticationService.token = null;
        AuthenticationService._http = http;
    }

    public static authenticate(authKey: string, callback?: (headers: HttpHeaders) => void) {
        if (!this.token) {
            this.headers = new HttpHeaders();
            this.authKey = authKey;

            this.headers = this.headers.set('Content-Type', 'application/json');
            this.headers = this.headers.set('AuthKey', this.authKey);

            this.getToken(this.headers).subscribe((data) => {
                this.token = data.token;
                this.headers = this.headers.set('Authorization', 'Bearer ' + this.token);
                if (callback) callback(this.headers);
            });
        }
        else {
            if (callback) callback(this.headers);
        }
    }

    static getToken(headers: HttpHeaders) {
        var url = 'catalogapi/tfiaspnetcore/api/token';
        var response = AuthenticationService._http.post(url, '', { headers: headers }).pipe(
            map(res => res as any)
        );
        return response;
    }
}
