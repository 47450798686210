import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TfimiscProvider, FirebaseUser, GLUser } from '../../services/tfimisc/tfimisc';

@Component({
  selector: 'app-edit-gl',
  templateUrl: './edit-gl.component.html',
  styleUrls: ['./edit-gl.component.scss'],
})
export class EditGLComponent implements OnInit {
  // @Input() genMaster: any = new GLUser();
  @Input() key: string = '';
  @Input() glCode: string = '';
  @Input() description: string = '';
  @Input() selectedUsers: string[] = [];
  @Input() users: Array<FirebaseUser> = [];
  @Input() glUserFormGroup: FormGroup;

  editing: boolean = false;
  submitted: boolean = false;

  get f() {
    return this.glUserFormGroup.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private tfimiscProvider: TfimiscProvider
  ) {
  }

  ngOnInit() {
  }

  editButtonClick(genMaster) {
    this.editing = !this.editing;
  }

  usersChanged() {
  }

  onSubmit(genMaster) {
    this.submitted = true;

    if (!this.glUserFormGroup.invalid) {
      this.tfimiscProvider.updateGLUsers(this.glUserFormGroup.value);
    }
  }

  deleteButtonClick() {
    console.log(this.glUserFormGroup.value);
    this.tfimiscProvider.deleteGenMaster(this.glUserFormGroup.value);
  }
}
