import { Injectable } from '@angular/core';
import { FbRtdbProvider } from '../fb-rtdb/fb-rtdb';
import { map } from 'rxjs/operators';
import { DataSnapshot } from '@angular/fire/database/interfaces';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/first';
import { AngularFireDatabase } from '@angular/fire/database';
import { resolve } from 'dns';
import { tfimiscFBConfig } from '../../app.constants';

/*
  Generated class for the TfimiscProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class TfimiscProvider {
  private usersPath: string = 'authentication/users';
  private glUsersPath: string = 'tfiReports/syspro/glUsers';
  private glMovementValuesAdminsPath: string = 'tfiReports/syspro/glMovementValuesAdmins';

  private glUsersRef: firebase.database.Reference = this.fbDb.getReference(this.glUsersPath);

  constructor(
    private fbDb: FbRtdbProvider,
    public db: AngularFireDatabase
  ) {
  }

  setDb(newdb: AngularFireDatabase) {
    this.db = newdb;
  }

  async getUsers(): Promise<any> {
    let returnValue: Array<FirebaseUser> = [];

    let data = await this.db.list(this.usersPath).valueChanges().first().toPromise();

    let resultArray: Array<any> = data;

    resultArray = resultArray.sort((a, b) => {
      if (a.email > b.email) return 1;
      else return -1;
    });

    resultArray.forEach(value => {
      let email: string = value.email;

      returnValue.push({ userName: email.substring(0, email.indexOf('@')) });
    });

    return returnValue;
  }

  getGLUsers(): Observable<any[]> {
    return this.fbDb.list(this.glUsersPath);
  }

  updateGLUsers(user: GLUser) {
    this.db.database.ref(this.glUsersPath).child(user.key).update(user);
    // this.db.database.ref(this.glUsersPath).update(user);
    // this.deleteGenMaster(user).finally(() => {
    //   this.glUsersRef.push(user);
    // });
  }

  insertGLUser(user: GLUser) {
    this.db.database.ref(this.glUsersPath).push(user);
  }

  deleteGenMaster(user: GLUser): Promise<void> {
    if (user.key) return this.fbDb.remove(this.glUsersPath, user.key);
    else return new Promise((resolve) => { resolve(); });
  }

  getGLs(username: string, callback: (gls: Array<GLUser>) => void) {
    let gls: Array<GLUser> = [];

    this.db.database.ref(this.glUsersPath).orderByChild('child_added').on('value', snapshot => {
      snapshot.forEach(value => {
        let glUser: GLUser = value.val();
        if (glUser.users.filter(user => {
          let userName: string = user + '';
          return userName == username;
        }).length > 0) {
          gls.push(glUser);
        }
      });
      callback(gls);
    });
  }

  getGLAdmins(callback: (admins: Array<string>) => void) {
    this.db.database.ref(this.glMovementValuesAdminsPath).orderByValue().on('value', snapshot => {
      callback(snapshot.val());
    });
  }
}

export class FirebaseUser {
  public userName: string = '';
}

export class GLUser {
  public key: string;
  public glCode: string = '';
  public description: string = '';
  public users: Array<FirebaseUser> = [];
  public value: number;
  public budget: number;
}