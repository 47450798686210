import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication-service';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class EncoreCompany1Service {

    static token: string;
    static _http: HttpClient;
    static authKey: string;

    constructor(private http: HttpClient
    ) {
        EncoreCompany1Service._http = http;
    }

    static getGlHistory(glCode: string, year: string) {
        var url = 'catalogapi/tfiaspnetcore/api/encorecompany1/getglhistory';
        var response = this._http.post(url, {glCode: glCode, year: year}, { headers: AuthenticationService.headers });
        return response;
    }

    static getTankQuantitiesSold(tqsParameters: any) {
        var url = 'catalogapi/tfiaspnetcore/api/encorecompany1/gettankquantitiessold';
        var response = this._http.post(url, tqsParameters, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static getStockCodes() {
        var url = 'catalogapi/tfiaspnetcore/api/encorecompany1/getstockcodes';
        var response = this._http.get(url, { headers: AuthenticationService.headers }).pipe(
            map(res => res as Array<any>)
        );
        return response;
    }

    static getGlBudget(glCode: string) {
        var url = 'catalogapi/tfiaspnetcore/api/encorecompany1/getbudgets';
        var response = this._http.post(url, {glCode: glCode}, { headers: AuthenticationService.headers });
        return response;
    }
}
